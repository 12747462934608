<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="text-center text-body-1 font-weight-light">
          {{ $trans('account_activation_resend_text') }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center justify-center"
      >
        <v-btn
          color="primary"
          height="56px"
          depressed
          :loading="isLoading"
          @click="sendActivationEmailHandle"
        >
          {{ $trans('account_activation_resend_button') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from 'vue-typed-mixins'
import DialogMixins from '@/components/dialogs/mixins/DialogMixins'
import { mapActions } from 'vuex'
import { errorNotification, successNotification } from '@/calendesk/prototypes/notifications'

export default mixins(DialogMixins).extend({
  name: 'AccountActivationDialog',
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapActions({
      resendActivationLink: 'user/resendActivationLink'
    }),
    sendActivationEmailHandle () {
      this.isLoading = true
      // HACK: we should not guess on which dialog this is shown. Bad design.
      this.resendActivationLink(this.dialog.data.email || this.dialog2.data.email)
        .then(() => {
          successNotification(this.$trans('confirm_send_activate_link_dialog_subtitle'))
          this.$emit('close')
        })
        .catch(error => {
          errorNotification('send_again_fail', error, false)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
})
</script>
